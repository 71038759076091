<template>
  <div class="cart-wrapper">
    <H2 :title="h2.title" :color="h2.color" :id="h2.id"/>
    <div class="cart">
      <div v-if="productStore.getIsCartEmpty">
        <p>{{ cms.page.fgNoItemsInCart }}</p>
      </div>

      <div v-for="cartItem in productStore.cartComplete" class="cart-item">
        <router-link :to="{ name: 'StoreProducts', params: { id: cartItem.specialProductId }}">
          <div class="cart-img cart-img-desc">
            <img :src="cartItem.imagePath" class="img-fluid"/>
          </div>
        </router-link>
        <div class="cart-body">
          <div class="cart-body-mob">
            <router-link :to="{ name: 'StoreProducts', params: { id: cartItem.specialProductId }}">
              <div class="cart-img cart-img-mob">
                <img :src="require(`../assets/images/${cartItem.specialProductId}.png`)" class="img-fluid"/>
              </div>
            </router-link>
            <div class="cart-body-top">
              <router-link :to="{ name: 'StoreProducts', params: { id: cartItem.specialProductId }}">
                <h4 class="cart-title">
                  {{ productStore.getProductById(cartItem.specialProductId.toString()).title }}
                </h4>
              </router-link>
              <div class="cart-quantity">
								<span class="cart-price">
                  {{ this.showFormattedPrice(cartItem.price) }}
                </span>
                <div class="cart-quant">
                  <InputNumber
                      v-model.number="localCart[cartItem.specialProductId]"
                  />
                </div>
                <span class="cart-total-price">
                  {{ this.showFormattedPrice(cartItem.price * cartItem.quantity) }}
                </span>
              </div>
            </div>
          </div>
          <p class="cart-text ">{{ cartItem.text }}</p>
          <div class="cart-btn">
            <button
                type="button"
                class="btn btn-alternate btn-update btn-equal-width"
                v-bind:class="{
                  'btn-inactive':
                  localCart[cartItem.specialProductId] === productStore.getItemFromCartComplete(cartItem.specialProductId).quantity
                }"
                @click="updateQuantity(cartItem.specialProductId)"
            >
              <!-- Working state -->
              <span
                  v-if="cartItem.specialProductId === cart.specialProductId && cart.isUpdating && !cart.errorDuringUpdate"
              >
                  {{ cms.page.fgUpdating }}
                  <i class="fa-solid fa-spinner fa-spin margin-icon-left"></i>
                </span>

              <!-- Work completed, but ERROR state (switched off by a JS Timeout function) &ndash;&gt;-->
              <span v-else-if="cartItem.specialProductId === cart.specialProductId && cart.errorDuringUpdate">
                  {{ cms.page.fgError }}
                  <i class="fa-solid fa-circle-exclamation fa-lg margin-icon-left"></i>
                </span>

              <!-- Default state -->
              <span v-else="cartItem.specialProductId !== cart.specialProductId">{{ cms.page.fgUpdateQuantity }}</span>

            </button>

            <button
                type="button"
                class="btn btn-alternate btn-remove btn-equal-width"
                @click="removeItemFromCart(cartItem.specialProductId)"
            >
              <!-- Default state -->
              <span v-if="
              cartItem.specialProductId !== cart.specialProductId
              || (cart.isDeletingItem && cartItem.specialProductId !== cart.specialProductId)
              || (cart.isUpdating && !cart.isDeletingItem)
              || cart.recentlyUpdated">
                {{ cms.page.fgRemove }}
                <i class="fa-solid fa-trash-can margin-icon-left hide-icon-mobile"></i>
              </span>

              <!-- Working state -->
              <span
                  v-if="
                  cartItem.specialProductId === cart.specialProductId
                  && cart.isUpdating
                  && !cart.errorDuringUpdate
                  && cart.isDeletingItem
                  "
              >
                {{ cms.page.fgRemoving }}
                <i class="fa-solid fa-spinner fa-spin margin-icon-left"></i>
              </span>

            </button>
          </div>
        </div>
      </div>

    </div>
    <div class="total-wrapper">
      <div class="total">
        <label class="total-label">Total</label>
        <span class="total-price">
          {{ this.showFormattedPrice(productStore.getCartTotal()) }}
        </span>

        <router-link :to="{ name: 'Checkout'}">
          <button v-if="!productStore.getIsCartEmpty" type="button" class="btn btn-default">
            {{ cms.page.fgCheckout }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import H2 from '@/components/H2'
import InputNumber from "../components/inputNumber";
import {mapStores} from 'pinia'
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore"
import {useDesignStore} from "@/stores/designStore"
import {useProductStore} from "@/stores/productStore"
import {storeMixin} from "@/mixins/storeMixin"

export default {
  name: "Cart",
  mixins: [storeMixin],
  components: {
    H2, InputNumber
  },
  data() {
    return {
      cart: {
        errorDuringUpdate: false,
        isDeletingItem: false,
        isUpdating: false,
        recentlyUpdated: false,
        specialProductId: '',
      },
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["h2", "input_number"],
          LanguageCode: "",
          PageName: "cart",
          MinistryUpdates: false
        },
        page: {},
      },
      h2: {
        title: 'Items In Your Cart',
        color: 'color-black',
        id: 't1'
      },
      isCartLoaded: false,
      localCart: {},
    }
  },
  computed: {
    ...mapStores(useAuthStore, useCmsStore, useDesignStore, useProductStore),
  },
  async created() {
    this.setTitlePageFromCms()
    this.resetLocalCartQuantities(true)
    this.setCartToInitialState()
    this.setContentLoaded(false)
    await this.productStore.getCartItems(this.authStore.getRegistrationId, true)
    await this.fetchCmsData()
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setTitlePageFromCms(results)

              this.setContentLoaded(true)
              // this.h2.title = this.cms.page.fgItemsInYourCart
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    async removeItemFromCart(productId) {
      if (this.cart.isUpdating || this.cart.errorDuringUpdate || this.cart.recentlyUpdated) return false

      this.cart.isDeletingItem = true
      await this.addItemToCart(productId, 0, false, true)
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
    async updateQuantity(productId) {
      if (
          this.cart.isUpdating
          || this.cart.errorDuringUpdate
          || this.localCart[productId] === this.productStore.getItemFromCartComplete(productId).quantity
      ) return false

      await this.addItemToCart(productId, this.localCart[productId], true)
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgItemsInYourCart ? page.fgItemsInYourCart : 'Items in your cart'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: '',
        img_class: 'bg-cart',
        btn_history: true,
      });
    },
  },
}
</script>


<style lang="scss" scoped>
.container-s {
  h2 {
    color: #0c2e3c;
  }
}

.cart-wrapper {
  width: 1400px;
  max-width: 100%;
  padding: 50px 15px;
  margin: 0 auto;

  > h2 {
  }

  .cart {
    margin-top: 90px;

    .cart-item {
      display: flex;
      color: #0c2e3c;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      .cart-img {
        img {
          width: 200px;
          @include MQ(M) {
            max-width: 200px;
          }
          @media (min-width: 1100px) {
            width: 348px;
          }
        }
      }

      .cart-body {
        width: 100%;

        .cart-body-mob {
          > a {
          }

          .cart-body-top {
            @include MQ(M) {
              border-bottom: 1px solid rgba($blue-darker, 0.4);
              padding-bottom: 20px;
              margin-bottom: 20px;
            }

            > a {
              color: $blue-darker;
              transition: all .33s ease;

              &:hover {
                color: $orange;
              }

              h3.cart-title {
              }
            }
          }
        }
      }
    }
  }
}

.cart {


  &-price {
    color: $blue-darker;
  }

  &-total-price {
    font-weight: 600;
    text-align: right;
  }


  &-text {
    margin-bottom: 10px;
    color: #0c2e3c;
  }

  &-btn {
    display: flex;
    justify-content: flex-end;
    @media (max-width: $sm) {
      justify-content: space-evenly;
    }

    .btn {
      min-width: 128px;
      @media (max-width: $sm) {
        flex: 0 0 45%;

      }
    }
  }
}

.icons-trash {
  width: 12px;
  height: 13px;
  background-image: url('../assets/images/icons-trash.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  margin-left: 15px;
}

.total-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0;
  margin: 50px 0 0;
  border-top: 1px solid rgba($blue-darker, 0.4);
}

.total {
  &-label {
    font-size: 18px;
    line-height: 33px;
    font-weight: 700;
    text-transform: uppercase;
    color: $blue-darker;
    display: block;
    text-align: right;
    color: #0c2e3c;
  }

  &-price {
    font-size: 45px;
    line-height: 55px;
    font-weight: 600;
    display: block;
    text-align: right;
    margin: 10px 0 40px;
  }
}

@media screen and (min-width: 768px) {
  .cart {
    &-quantity {
      display: flex;
      align-items: center;
    }

    &-price {
      width: 150px;
    }

    &-total-price {
      width: 220px;
    }

    &-img-mob {
      display: none;
    }
  }
}

@media screen and (min-width: 991px) {
  .cart {
    &-body-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1100px) {
  .cart {

    &-body {
      padding-left: 50px;
    }

    &-price {
      font-size: 18px;
      line-height: 33px;
    }

    &-total-price {
      font-size: 18px;
      line-height: 33px;
      color: $blue-darker;
    }

    &-text {
      font-size: 18px;
      line-height: 33px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1100px) {
  .cart {
    &-body {
      padding-left: 25px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .cart {
    &-img {
      img {
        max-width: 150px;
        min-width: 150px;
      }
    }

    &-price {
      font-size: 15px;
      line-height: 30px;
    }

    &-total-price {
      font-size: 15px;
      line-height: 30px;
      color: rgba($blue-darker, 0.4);
    }

    &-title {
      // font-size: 15px;
      // line-height: 23px;
    }

    &-text {
      font-size: 15px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .cart {
    &-quant {
      margin-top: 20px;
    }

    &-item {
      &:not(:last-child) {
        border-bottom: 1px solid rgba($blue-darker, 0.4);
        padding-bottom: 35px;
        margin-bottom: 35px;
      }
    }

    &-body-top {
      width: 100%;
    }

    &-total-price {
      display: block;
    }

    &-img-desc {
      display: none;
    }

    &-body-mob {
      display: flex;
      width: 100%;
    }

    &-text {
      margin: 27px 0;
    }
  }
  .total-wrapper {

    padding: 59px 15px 0;
    margin: 35px -15px;
  }
}

.btn-inactive {
  opacity: .4;

  &:before {
    cursor: not-allowed;
  }

}

.btn-update {
  margin-right: 32px;
  color: $blue-darker;

  &:before {
    background-color: $orange;
  }
}

@media screen and (min-width: $md) {
  div.cart-btn button.btn-equal-width {
    min-width: 225px;
  }
}

@media screen and (max-width: $sm) {
  .hide-icon-mobile {
    display: none;
  }
}

.margin-icon-left {
  margin-left: 12px;
}

</style>